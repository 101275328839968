.skills{
    width: 90%;
    display: flex;
    align-items: center;
    margin:auto;
    justify-content: space-between;
    position: relative;
    
    
}
.skils-container{
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    
    
 
}

.skill-left{
    flex: 1;
    align-items: center;
    padding: 2rem;
    position: relative;
    width: 100%;
}
.skill-left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.skill-right{
    flex: 1;
    align-items: center;
    justify-content: center;
    color:white;
    width: 100%;
    

}
.skill-right h2{
    font-size: 2rem;
    margin-bottom: 2rem;
    
}
.skill-bar span{
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 2rem;

    
}
.bar{
    width: 80%;
    height: 20px;
    background-color:#ccc;
    border-radius: 20px;
    position: relative;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
.bar-value{
    background-color: black;
    border-radius: 20px;
    position: absolute;
    height: 20px;
    text-align: center;
    
}
.blur-skill{
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: rgb(250, 193, 89);
    filter: blur(142px);
    z-index: -9;
    position: absolute;
}
.html{
    width: 85%;
}
.css{
    width: 75%;
}
.js{
    width: 60%;
}
.react{
    width: 56%;
}
.bootstraup{
    width: 65%;
}
.ps{
    width: 85%;
}
.bar-value span{
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
}

@media screen  and (max-width:810px){
    .skils-container{
        flex-direction: column;
        margin-top: 12rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    
}