.education{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    
 
}
.education h2{
    text-align: center;
    padding: 2rem 0;
    font-size: 3rem;
    color:white;
}
.box{
    width: 80%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: center;
    
    
}


.plan{
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    height: 350px;
    background-color: #3c3c3c;
    margin: 1% 1%;
    position: relative;
    cursor: pointer;
    color: white;
    line-height: 23px;
    

}
.plan:hover{
    box-shadow: 2px 2px 4px rgb(0,0,0,0.9),
                -2px -2px 4px rgb(0,0,0,0.9);
                background-color: orangered;
}
.plan :nth-child(1){
    max-width: 300px;
    width: 100%;
    height: 150px;
    position: relative;
    object-fit: cover;
   margin-bottom: 1rem;


}

.plan :nth-child(2){
    font-size: 1.2rem;
    font-weight: 600;
    margin: 5px 10px;
   
    
}
.plan :nth-child(3){
font-size: 1.2rem;
margin-bottom: 5px;
margin-left: 10px;
}
.head{
    font-weight: 600;
}
.plan :nth-child(4){
font-size: 1.2rem;
font-weight: 500;
margin-bottom: 5px;
margin-left: 10px;
}
.plan :nth-child(5){
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 10px;
    display: flex;

}
.plan :nth-child(6){
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 10px;
    flex-wrap: wrap;
    position: relative;
    display: flex;
    
}
.experince{
    margin: auto;
    width: 80%;
}
.experince-content{
    line-height: 20px;
}
.experince h4{
    background-color: orangered;
    width: fit-content;
     font-size: 2rem;
     font-weight: 700;
     padding: 8px 10px;
     position: relative;
}
.experince h4 span{
    border: 2px solid orangered;
    position: absolute;
    background-color:transparent;
    padding:30px 90px;
    margin-left: -160px;
    margin-top: -16px;
    
}
.experince h5{
    font-size: 1.2rem;
    margin-top: 2rem;
    color: aliceblue;

}
.experince h5>span{
    font-size: 1rem;
    font-weight: 500;
    
}