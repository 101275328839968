*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  background-color:#3c3f45 ;
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
