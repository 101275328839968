.hero-container{
display: flex;
align-items: center;
gap: 6rem;
height: 100vh;
flex-wrap: wrap;
position: relative;
justify-content: center;

}
.hero-left{
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}
.hero-right{
    flex: 1 ;
    display: flex;
    align-items:center ;
    margin: 0 30px;
    justify-content: center;
    flex-direction: column;
    
}
.hero-title{
    margin-top: 1rem;   
    color: white;
    text-align: center;
    

}
.hero-title h4{
    font-size: 2rem;
    padding-bottom: 1rem;
}
.hero-title h2{
    font-size: 3.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
} 
.title-animation{
    width:1px;
    height: 90px;
    background-color: orangered;
    animation: title 3.5s steps(10) infinite;
    
}
@keyframes title {
    100%{
        width:230px;
    }
    
}
.title-animation h3{ 
    font-size: 2.5rem;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-bottom: 3rem;

}

.btn{
    margin: 40px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    display: flex;
    align-items: center;
    
    
}
.btn a{
    padding: 10px 20px ;
    border: 2px solid white;
    margin:  10px;
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 4px;
    transition: .3s ease;
}
.btn a:nth-child(1){
    background-color: orangered;
    border: none;
    
}
.btn a:nth-child(1):hover{
    background-color: white;
    color: orangered;
}
.btn a:nth-child(2){
    padding: 10px 40px;
}
.btn a:nth-child(2):hover{
    background-color: orangered;
    border: none;
    cursor: pointer;
    
    
}
.blur{
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: rgb(250, 193, 89);
    filter: blur(182px);
    z-index: -9;
}
.profile-box{
width: 250px;
height: 350px;
background-color:transparent;
border: 2px solid orangered;
left: 12rem;
position: absolute;
animation: one 2.5s steps(10) infinite;
bottom: 10rem;
}
@keyframes one {
    100%{
        left:10rem ;
    }
    
}

.profile{
    background-color:orangered;
    width: 250px;
    height: 350px;
    margin-top: 2rem;
    position: absolute;
    left: 12rem;
    animation: two 2.5s steps(10) infinite ;
}
@keyframes two {
    100%{
        left:15rem ;
    }
   
}
.profile-img{
    width: 250px;
    height: 350px;
    background-color: blue;
    position: absolute;
    left: 12rem;
    
}
.profile-img img{
    width: 100%;
    height: 100%;
}
