@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
.header{
    display: flex;
    justify-content: space-between;
    background-color:#3c3f45;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
}
.nav-head{
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;
    margin: auto;
}



.nav-logo{
    display: flex;
    align-items: center;
}
.nav-logo h1{
    font-size: 3rem;
    color: red;
    font-family: 'Dancing Script', cursive;
}
.nav-logo span{
    color: white;
    font-family: 'Dancing Script', cursive;
}
.navbar{
    display: flex;
    align-items: center;

}
.nav-menu{
    list-style: none;
    align-items: center;
    display: flex;
    gap: 3rem;
    position: relative;
}
.nav-list{
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}
.nav-list:hover{
    color: orangered;
    transform: scale(1.2);
}
.nav-icon{
   cursor: pointer;
   opacity: 0;
}
.nav-icon i{
   
    font-size: 2rem;
    color: #fff;
}

@media screen and (max-width:900px){

    .nav-menu-r{
        flex-direction:column;
        position:absolute;
        justify-content: center;
        align-items: center;
        background-color: #3c3f45;
        top: 56px;
        width: 100%;
        right: 0%;  
        padding-bottom: 1rem;
        transition: 1s ease;
        text-align: center;
        list-style: none;
    }
    .nav-menu-r li{
        margin: 40px 0;
    }
    .nav-menu{
        display: none;
    }
    
   
    .nav-icon{
        opacity: 1;
    }
    
    
}