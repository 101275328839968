.project{
    width: 90%;
    margin: auto;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    
}
.project h1{
    text-align: center;
    padding: 2rem 0;
    font-size: 3rem;
}
.project-container{
flex-wrap: wrap;
display: flex;
align-items: center;
justify-content: center;
}
.proj{
    width: 300px;
    height: 300px;
    background-color: #3c3c3c;
    margin: 2rem;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    cursor: pointer;
    position: relative;
}
.proj img{
    width: 300px;
    height: 300px;
    object-fit: contain;
    overflow-y: scroll;
    overflow: hidden;
    position: absolute;
}
.proj-content{
    width: 300px;
    height: 300px;
    background-color:orangered;
    position: absolute;
    top: .01px;
    z-index: 2;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    opacity: 0;
    transition: 1s ease;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}
.proj-content:hover{
    opacity: 1;
}
.proj-content>span:nth-child(1){
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
.proj-content>span:nth-child(2){
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.proj-content a{
 font-size: 1rem;
 color: #000;
 text-decoration: none;
 text-align: center;
 padding: 6px 20px;
 background-color: #fff;
 border-radius: 10px;

}
.blur-continaer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    
}
.blur-left{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: rgb(250, 193, 89);
    filter: blur(142px);
    z-index: -9;
    
  
}
.blur-right{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: rgb(250, 193, 89);
    filter: blur(142px);
    z-index: -9;
    margin-right:5rem;
    
}